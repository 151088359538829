<script setup lang="ts">
import type { Promotion } from "@/types";

const { t } = useT();
const loginGuard = useLoginGuard();
const router = useRouter();
const { open } = useAppModals();
const { data: appInit } = useAppInitData();
const { promotionCards, openCash } = usePromotionsData();
const { referralInfo, handleClickCopyLink, execute: executeReferralsInfo } = useReferralsInfo();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const currencySymbol = computed(() => appInit.value?.currencySymbol ?? "$");
const wheelText = computed(() =>
	appInit.value?.isGuest
		? "Create an account and get your prize now!"
		: "Boost your game with free coins - get your prize now!"
);

const handleQualificationClick = (event: MouseEvent) => {
	loginGuard({
		success: () => {
			event?.stopPropagation();
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};

const handleNavigateToPromotions = () => {
	loginGuard({
		success: () => {
			router.push("/promotions/");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
const getImgFullPath = (src: string) => {
	const isNuxtImg = src.includes("nuxt-img");
	return isNuxtImg ? src : `${baseImageUrl}${src}`;
};

const handleClick = (promotion: Promotion) => {
	loginGuard({
		success: () => {
			if (promotion?.data?.enabled) {
				dispatchGAEvent({ event: "click_button", location: "home", buttonName: "make_deposit" });
				window?.$store?.gaCash?.deposit?.({
					location: "home",
					step: "view_payments_window",
					activePackage: promotion?.data?.money
				});

				openCash(promotion);
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleWheelClick = () => {
	loginGuard({
		success: () => {
			open("LazyOModalDailyWheel");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleSeasonClick = () => {
	loginGuard({
		success: () => {
			if (appInit.value?.season?.isEnabled) {
				open("LazyOModalSeasonCards");
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleInviteClick = () => {
	loginGuard({
		success: async () => {
			if (!referralInfo?.value?.referralUrl) {
				await executeReferralsInfo();
			}

			handleClickCopyLink();
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<MHomeSectionWrapper
		class="section-promotions"
		title="Promotions"
		tidTitle="promotions"
		tidShow="promo-show-all"
		icon="home/promotion"
		is-show-all
		:handleClickNavigate="handleNavigateToPromotions"
	>
		<div v-if="appInit?.prizeDrops?.isActive && !appInit?.isGuest" class="keen-slider__slide" data-tid="promo-card">
			<MPromotionPrizeDrops data-tid="promo-coin-drop" />
		</div>
		<div v-if="appInit?.scratchCardLottery?.isActive" class="keen-slider__slide" data-tid="promo-card">
			<MPromotionScratchCards data-tid="promo-scratch-card" />
		</div>
		<div v-if="appInit?.magicBox?.isActive" class="keen-slider__slide" data-tid="promo-card">
			<MPromotionMagixBoxesPumpkins />
		</div>
		<template v-if="promotionCards?.length && promotionCards.length > 0">
			<div
				v-for="(card, index) in promotionCards"
				:key="card?.id || index"
				class="keen-slider__slide"
				data-tid="promo-card"
			>
				<MPromotionBingo v-if="card.type === 'bingo' && appInit?.bingo?.isActive" />
				<MPromotionVipClub v-else-if="card.type === 'rankLeagueCashback'" />
				<MPromotionVipClubFreeSpin
					v-else-if="card.type === 'playerFreeSpin'"
					:imagePopup="getImgFullPath('/nuxt-img/promotion/free-spin-bg.png')"
					:spinsRemain="card.data?.spinsRemain"
					:betLevel="card.data?.betLevel"
					:gameId="card.data?.gameId"
				/>
				<MPromotionWheelOfTreasure v-else-if="card.type === 'depositStreak'" />
				<MPromotionInviteQualification
					v-else-if="card.type === 'partnerReferralQualification'"
					:image="getImgFullPath('/nuxt-img/promotion/invite-affiliate-bg.png')"
					:coins="card.data?.bonusCoins || 0"
					:entries="card.data?.bonusEntries || 0"
					:depositCondition="card.data?.baseline || 0"
					@click="handleQualificationClick"
				/>
				<MPromotionTournament v-else-if="card.type === 'tournament'" />
				<MPromotionIndependenceSeason
					v-else-if="card.type === 'season'"
					:finishedAt="card?.data?.finishedAt"
					@click="handleSeasonClick"
				/>
				<MPromotionWheel
					v-else-if="card.type === 'dailyWheel'"
					:image="getImgFullPath('/nuxt-img/wheel-promo/bg.png')"
					:type="card.type"
					:text="t(wheelText)"
					@click="handleWheelClick"
				/>
				<MPromotionInvite
					v-else-if="card.type === 'invite'"
					image="/nuxt-img/promotion/invite-bg.png"
					:coins="card.package?.coins || 0"
					:entries="card.package?.entries || 0"
					@click="handleInviteClick"
				/>
				<MPromotionItem
					v-else
					:type="card.type"
					:enabled="card.data?.enabled"
					:showAvailable="!!card.data?.promoOfferPreset?.availableTill"
					:image="getImgFullPath(card.package?.imagePopup || '')"
					:coins="card.package?.coins || 0"
					:entries="card.package?.entries || 0"
					:badge="card.badgeLabel"
					:bestDeal="!!card.data?.promoOfferPreset?.bestDeal"
					:mostPopular="!!card.data?.promoOfferPreset?.mostPopular"
					:specialOffer="false"
					:date="card.date"
					:money="card.data?.money"
					:currencySymbol="currencySymbol"
					:subType="card.data?.promoOfferPreset?.subType"
					:title="card?.data?.promoOfferPreset?.title"
					@click="handleClick(card)"
				/>
			</div>
		</template>
	</MHomeSectionWrapper>
</template>

<style scoped lang="scss">
.section-promotions {
	padding: 24px 0;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: 0 16px;
	}
}
</style>
